@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500&display=swap);
.tableOperations {
  margin-bottom: 16px;
}

.tableOperations > button {
  margin-right: 8px;
}
/* .ant-drawer-mask {
  position: relative !important;
} */

.trBorder {
  border-bottom: 1px dotted black;
  /* float: right; */
  width: "30%";
}

.table-border {
  border-top: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  padding: 10px;
}

.table-border-second {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  padding: 10px;
}

.table-border-all {
  border-collapse: collapse;
  border-style: hidden;
  border-bottom: 1px solid black;
  padding: 0px;
}

.table-border-slip {
  border-collapse: collapse;
}

.table-border-slip th {
  border: 1px solid black;
  padding: 5px;
}
.table-border-slip td {
  border: 1px solid black;
  padding: 5px;
}
.table-border-slip td:first-child {
  /* border-left: 0px; */
}
.table-border-slip td:last-child {
  /* border-right: 0px; */
}
.table-border-slip th:first-child {
  /* border-left: 0px; */
}
.table-border-slip th:last-child {
  /* border-right: 0px; */
}
.table-border-slip td:nth-child(3) {
  width: 15%;
}
.table-border-slip td:nth-child(3) {
  width: 28.3%;
}
.table-border-slip th:nth-child(3) {
  /* width: 3%; */
}
.table-border-slip th {
  border-top: 0px;
}

.column-class {
  padding: 0px;
}

.table-border th,
td {
  padding: 10px;
}

.data-row-active {
  animation: myfirst 2s 1;
  -webkit-animation: myfirst 2s 1; /* Safari and Chrome */
}

/* invoice table */
/* .table-borderless td:first-child {
  background-color: blue;
  color: black;
  width: 20%;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
}
.table-borderless td:last-child {
  background-color: green;
  width: 40%;
} */

.table-borderless {
  table-layout: fixed;
  width: 100% !important;
}
.table-borderless td {
  width: 30px;
  padding: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-borderless-sa td {
  padding: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.font-text {
  font-weight: 1000 !important;
}
.margin-all {
  margin: 0px !important;
}

@keyframes myfirst {
  100% {
    background: var(--main-color);
  }
}

@-webkit-keyframes myfirst /* Safari and Chrome */ {
  100% {
    background: var(--main-color);
  }
}

:root {
  --main-color: #635ac7;
  --secondary-color: #3e3887;
  --text-color: #646f79;
  --font-family: "Roboto";
  --font-weight: 300;
}
::-webkit-input-placeholder {
  font-family: "Roboto";
  font-family: var(--font-family);
  font-weight: 300;
  font-weight: var(--font-weight);
}
::-moz-placeholder {
  font-family: "Roboto";
  font-family: var(--font-family);
  font-weight: 300;
  font-weight: var(--font-weight);
}
:-ms-input-placeholder {
  font-family: "Roboto";
  font-family: var(--font-family);
  font-weight: 300;
  font-weight: var(--font-weight);
}
::-ms-input-placeholder {
  font-family: "Roboto";
  font-family: var(--font-family);
  font-weight: 300;
  font-weight: var(--font-weight);
}
::placeholder {
  font-family: "Roboto";
  font-family: var(--font-family);
  font-weight: 300;
  font-weight: var(--font-weight);
}

html,
body {
  font-family: "Roboto";
  font-family: var(--font-family);
  font-weight: 300;
  font-weight: var(--font-weight);
}

.ant-menu-item-selected {
  background: rgba(255, 255, 255, 0.16) !important;
}

.btn {
  font-size: "16px";
  border: none !important;
  background-color: #635ac7 !important;
  background-color: var(--main-color) !important;
  font-family: "Roboto" !important;
  font-family: var(--font-family) !important;
  font-weight: 300 !important;
  font-weight: var(--font-weight) !important;
}

.login-container {
  /* background-color: #f6f8f9; */
  border-radius: 3px;
  /* box-shadow: 0 20px 30px 0 rgba(36, 50, 66, 0.14); */
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  /* padding: 32px; */
  /* position: relative; */
  width: 50%;
}

.title-text {
  /* text-align: center; */
  font-family: "Roboto" !important;
  font-family: var(--font-family) !important;
  font-weight: 300 !important;
  font-weight: var(--font-weight) !important;
}

.text-box {
  height: 50px !important;
  border: 2px solid #e0e6e8 !important;
}

.submit-btn {
  letter-spacing: ".5px";
  height: 50px !important;
  font-size: "16px";
  border: none !important;
  width: 100%;
  background-color: #635ac7 !important;
  background-color: var(--main-color) !important;
  font-family: "Roboto" !important;
  font-family: var(--font-family) !important;
  font-weight: 300 !important;
  font-weight: var(--font-weight) !important;
}

.submit-btn:hover {
  background-color: #3e3887 !important;
  background-color: var(--secondary-color) !important;
  border-color: transparent !important;
}

.submit-btn:not(:disabled):not(.disabled):active {
  background-color: #3e3887;
  background-color: var(--secondary-color);
  border-color: transparent;
}

/* rgba(255,255,255,.16) */

