@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500&display=swap");
:root {
  --main-color: #635ac7;
  --secondary-color: #3e3887;
  --text-color: #646f79;
  --font-family: "Roboto";
  --font-weight: 300;
}
::placeholder {
  font-family: var(--font-family);
  font-weight: var(--font-weight);
}

html,
body {
  font-family: var(--font-family);
  font-weight: var(--font-weight);
}

.ant-menu-item-selected {
  background: rgba(255, 255, 255, 0.16) !important;
}

.btn {
  font-size: "16px";
  border: none !important;
  background-color: var(--main-color) !important;
  font-family: var(--font-family) !important;
  font-weight: var(--font-weight) !important;
}

.login-container {
  /* background-color: #f6f8f9; */
  border-radius: 3px;
  /* box-shadow: 0 20px 30px 0 rgba(36, 50, 66, 0.14); */
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  /* padding: 32px; */
  /* position: relative; */
  width: 50%;
}

.title-text {
  /* text-align: center; */
  font-family: var(--font-family) !important;
  font-weight: var(--font-weight) !important;
}

.text-box {
  height: 50px !important;
  border: 2px solid #e0e6e8 !important;
}

.submit-btn {
  letter-spacing: ".5px";
  height: 50px !important;
  font-size: "16px";
  border: none !important;
  width: 100%;
  background-color: var(--main-color) !important;
  font-family: var(--font-family) !important;
  font-weight: var(--font-weight) !important;
}

.submit-btn:hover {
  background-color: var(--secondary-color) !important;
  border-color: transparent !important;
}

.submit-btn:not(:disabled):not(.disabled):active {
  background-color: var(--secondary-color);
  border-color: transparent;
}

/* rgba(255,255,255,.16) */
